import {  useState } from 'react';
import backIcon from '../../assets/images/svgs/arrow-black.svg';
import infoIcon from '../../assets/images/svgs/info.svg';
import AmbassadorInfo from '../../components/ambassador-info';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { changeScreen } from '../../store/auth/auth-action';
import { Button } from '../../components/button';
import { User } from '../../types/api';
import MenuHeader from '../../components/menu-header';

const AmbassadorScreen: React.FC = () => {
   const dispatch = useAppDispatch()
      const user = useAppSelector((state) => state.auth.user) as User
    const [showInfo, setShowInfo] = useState(false);
    const { t } = useTranslation();

    const _goBack = () => {
        dispatch(changeScreen('Menu Screen'));
    };

    const _showInfoModal = () => {
        setShowInfo(true);
    };

    const _hideInfoModal = () => {
        setShowInfo(false);
    };
    const _refLinkButtonClick = () => {
        console.log(user)
        const linkToCopy = user.ambassador_link;
        if (linkToCopy) {
            navigator.clipboard.writeText(linkToCopy)
                .then(() => {
                    console.log('Copied to clipboard:', linkToCopy);
                })
                .catch(err => {
                    console.error('Failed to copy text to clipboard:', err);
                });
        } else {
            console.warn('No link available to copy.');
        }
    };

    return (
        <div className="ambassador-container">
            <MenuHeader
        title={t('Ambassador program')}
        onInfoClick={_showInfoModal}
      />
            {/* <div className="ambassador-balance">
                <div className="ambassador-balance__info">
                    <span className="ambassador-balance__type">
                        {t('AP Ui showcase1')}:
                    </span>
                    <strong className="ambassador-balance__amount">
                        {ambassadorDetails?.referrals || 0}
                    </strong>
                </div>
                <div className="ambassador-balance__info">
                    <span className="ambassador-balance__type">
                        {t('AP Ui showcase2')}:
                    </span>
                    <strong className="ambassador-balance__amount">
                        {ambassadorDetails?.rewards || 0}
                    </strong>
                </div>
            </div> */}
            <div className='ambassador-container__description'>
            <strong>Everyone can now become a PAI ambassador!</strong> 
            <p>You can simply create your own personal ambassador link. If your friends and family sign up using 
            your link, you will receive a variety of ambassador benefits.</p>
            <p>The more people you help improve their pension, the higher your personal pension rewards will be. 
            You will receive a bonus pension contribution every time someone you referred shops via PAI!</p> 
            </div>
            <div className="ambassador-container__action">
            <Button variant="white-outlined" text={t('Copy Referal Link')} onClick={_refLinkButtonClick} />           

            </div>

            {showInfo && <AmbassadorInfo hideInfo={_hideInfoModal} />}
        </div>
    );
};

export default AmbassadorScreen;

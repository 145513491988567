import { useEffect, useState } from 'react';
import './styles.scss';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setCountryId } from '../../store/country/country-action';
import close from '../../assets/images/svgs/close.svg';
import { Button } from '../../components/button';
import { PasswordField } from '../../components/password-field';
import TextField from '../../components/text-field';
import { getAccessTokenSignUp, signUp } from '../../services/api';
import { CLIENT_ID, CLIENT_SECRET } from '../../services/config';
import { changeScreen } from '../../store/auth/auth-action';
import { validateEmail } from '../../utils/_validateEmail';
import { useTranslation } from 'react-i18next';
import CountryDropdown, { countries } from '../../components/country-dropdown';

const language = navigator.language;

const SignUp = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currency, setCurrency] = useState<string>('');
  const [error, setError] = useState('');

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const country_id = useAppSelector((state) => state.country.country_id);
  const { access_token } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const referralCode = extractReferralCodeFromURL();
    console.log("referral_code", referralCode);
    getAccessTokenSignUp({
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: 'client_credentials',
      scope: 'ios',
    });

    // if (language?.startsWith('nl')) {
    //   setCurrency('EUR');
    // } else {
    //   setCurrency('SEK');
    // }
    
  }, []);

  const _closeSignUp = () => {
    dispatch(changeScreen('Start screen'));
  };

  const _setvalue = (SetState: (e: string) => void, e: string) => {
    SetState(e);
    setError('');
  };

  const extractReferralCodeFromURL = (): string | undefined => {
    const path = window.location.pathname; 
    const match = path.match(/\/invite\/([^/]+)/);
    return match ? match[1] : undefined; 
  };
  
  const _signUp = async () => {
    const referralCode = extractReferralCodeFromURL();
  
    console.log("first_name", firstName);
    console.log("last_name", lastName);
    console.log("email", email);
    console.log("password", password);
    console.log("password_confirmation", confirmPassword);
    console.log("currency", currency);
    console.log("country_id", country_id);
    console.log("referral_code", referralCode);
  
    const selectedCountry = countries.find((country) => country.id === country_id);
  
    if (!selectedCountry) {
      setError("Invalid country selected");
      return;
    }
  
    if (
      !email.trim() ||
      !password.trim() ||
      !confirmPassword.trim() ||
      !country_id.trim() ||
      !firstName.trim() ||
      !lastName.trim()
    ) {
      setError("Please fill all fields");
    } else if (!validateEmail(email)) {
      setError("Incorrect email");
    } else {
      await signUp(
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          password_confirmation: confirmPassword,
          currency: selectedCountry.currency, 
          country_id: country_id,
          referrer_code: referralCode,
        },
        access_token.access_token,
        setError
      );
    }
  };

  return (
    <div className="sign-up-container">
      <div
        className="sign-up-container__close-wrapper"
        onClick={_closeSignUp}
      >
        <img src={close} />
      </div>
      <div className="sign-up-container__body">
        <h3 className="sign-up-container_title">{t('sign_up')}</h3>
        <div className="sign-up-container__form-container">
          <TextField
            value={firstName}
            setValue={(e: string) => _setvalue(setFirstName, e)}
            label={t('first_name')}
          />
          <TextField
            value={lastName}
            setValue={(e: string) => _setvalue(setLastName, e)}
            label={t('last_name')}
          />
          <TextField
            value={email}
            setValue={(e: string) => _setvalue(setEmail, e)}
            label={t('email')}
          />
          <CountryDropdown
            value={country_id}
            setValue={(e: string) => _setvalue(setCountryId, e)}
            placeholderValue='Country of Residence'
          />
          <PasswordField
            value={password}
            setValue={(e: string) => _setvalue(setPassword, e)}
            label={t('password')}
          />
          <PasswordField
            value={confirmPassword}
            setValue={(e: string) =>
              _setvalue(setConfirmPassword, e)
            }
            label={t('confirm_password')}
          />
          {error && (
            <p style={{ color: 'red', fontWeight: 'bold' }}>
              {error}
            </p>
          )}
        </div>
        <div className="sign-up-container__bottom-container">
          <Button
            variant="white-outlined"
            text={t('next')}
            onClick={_signUp}
          />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
